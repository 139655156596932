html,
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  background-color: rgb(245, 245, 245);
}
.container {
  margin: 80px auto 0 auto;
  max-width: 1200px;
}
.nav-container {
  margin: auto;
}
.nav-container svg {
  color: #fff;
}
a {
  text-decoration: none;
}
